import { BreadcrumbsArrayItem } from '@components/breadcrumbs';
import { CreateOrEditBannerRequestCategory } from '@src/service/apis/generatedv3/banner-controller';
import { BannerVisibilityTypes, ModalTypes } from '@src/types/banner';

const HOMEPAGE_BANNER_BREADCRUMBS: Array<BreadcrumbsArrayItem> = [
  { linkUrl: '/banner', linkValue: 'Homepage Banner' },
  { linkUrl: '', linkValue: 'Listing' },
];

const LISTING_PAGE_CONFIRMATION_MODAL_DEFAULT_STATE = {
  isOpen: false,
  type: null,
  selectedItemInfo: null,
};

const CONFIRMATION_MODAL = {
  [ModalTypes.REORDER]: {
    heading: 'Are you sure to re-order the position?',
    content: 'The position will use the latest order that you have updated',
  },
  [BannerVisibilityTypes.SHOW_BANNER]: {
    heading: 'Are you sure to show the banner?',
    content:
      'The banner will be shown in the homepage with the latest version that you have updated',
  },
  [BannerVisibilityTypes.HIDE_BANNER]: {
    heading: 'Are you sure to un-show the banner?',
    content: 'The banner will not be shown in the homepage',
  },
  [ModalTypes.ADD]: {
    heading: 'Are you sure to add?',
    content:
      'Please make sure all data and files are correct before adding it to homepage',
  },
  [ModalTypes.EDIT]: {
    heading: 'Are you sure to edit?',
    content:
      'All existing data and files will be replaced with the latest version that you have updated',
  },
};

const BANNER_TABLE_HEADERS = [
  { label: 'Show', id: 'show' },
  { label: 'Position', id: 'position' },
  { label: 'Campaign Name', id: 'campaignName' },
  { label: 'EN URL', id: 'enUrl' },
  { label: 'ID URL', id: 'idUrl' },
  { label: 'EN Image', id: 'enImage' },
  { label: 'ID Image', id: 'idImage' },
  { label: 'Actions', id: 'actions' },
];

const BANNER_CATEGORY_OPTIONS = ['PROMOTION', 'EDUCATION', 'UPDATES'];

const SUPPORTED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const ROWS_PER_PAGE_OPTIONS = [10, 50, 100];

const BANNER_RESOLUTION_FIREBASE_DEFAULT_VALUE = {
  [CreateOrEditBannerRequestCategory.PROMOTION]: {
    width: 320,
    height: 160,
    sizeInKB: 600,
  },
  [CreateOrEditBannerRequestCategory.EDUCATION]: {
    width: 320,
    height: 120,
    sizeInKB: 600,
  },
  [CreateOrEditBannerRequestCategory.UPDATES]: {
    width: 320,
    height: 120,
    sizeInKB: 600,
  },
};

const ALL_BANNER_RESOLUTIONS_DEFAULT_VALUE = {
  [CreateOrEditBannerRequestCategory.PROMOTION]: {
    width: 0,
    height: 0,
    sizeInKB: 0,
  },
  [CreateOrEditBannerRequestCategory.EDUCATION]: {
    width: 0,
    height: 0,
    sizeInKB: 0,
  },
  [CreateOrEditBannerRequestCategory.UPDATES]: {
    width: 0,
    height: 0,
    sizeInKB: 0,
  },
};

const INVALID_NUMERIC_CHARACTERS = ['e', 'E', '-', '+', '.'];

const POSITION_ERROR_MESSAGE =
  'Please fill position of the banner in correct order start from 1';

const CATEGORY_FIELD_TOOLTIP_TEXT =
  'Banner images will be reset if category is changed';

export {
  HOMEPAGE_BANNER_BREADCRUMBS,
  LISTING_PAGE_CONFIRMATION_MODAL_DEFAULT_STATE,
  CONFIRMATION_MODAL,
  BANNER_TABLE_HEADERS,
  BANNER_CATEGORY_OPTIONS,
  SUPPORTED_IMAGE_TYPES,
  ROWS_PER_PAGE_OPTIONS,
  ALL_BANNER_RESOLUTIONS_DEFAULT_VALUE,
  BANNER_RESOLUTION_FIREBASE_DEFAULT_VALUE,
  INVALID_NUMERIC_CHARACTERS,
  POSITION_ERROR_MESSAGE,
  CATEGORY_FIELD_TOOLTIP_TEXT,
};
