import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  RemoteConfig,
} from 'firebase/remote-config';
import { useCallback } from 'react';

import globalConfig from '@src/config';
import { app } from '@src/utils/firebase-config';
import {
  LEGAL_DOCS_DEFAULT_VALUE,
  TERMS_AND_CONDITIONS_DEFAULT_VALUE,
} from '@containers/GROWTH/manual-kyc/constants';

import { FirebaseInputDataType, FirebaseOutputDataType } from './types';
import useMainStore from '@src/stores/main';
import { BANNER_RESOLUTION_FIREBASE_DEFAULT_VALUE } from '@src/constants/banner';
import * as Sentry from '@sentry/nextjs';

const {
  FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS,
  FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS_PROD,
  FETCH_TIMEOUT_MILLIS,
  FETCH_TIMEOUT_MILLIS_PROD,
} = globalConfig;

const useFirebaseRemoteConfig = (): {
  init: () => Promise<string>;
  getFirebaseRemoteConfig: <T extends FirebaseOutputDataType>(
    key: FirebaseInputDataType,
  ) => Promise<T | undefined>;
} => {
  const config = useMainStore(state => state.FBRC);
  const setConfig = useMainStore(state => state.setFBRC);

  const init = useCallback(async () => {
    const remoteConfig = getRemoteConfig(app);

    remoteConfig.settings.fetchTimeoutMillis =
      process.env.NODE_ENV == 'production'
        ? FETCH_TIMEOUT_MILLIS_PROD
        : FETCH_TIMEOUT_MILLIS;

    remoteConfig.settings.minimumFetchIntervalMillis =
      process.env.NODE_ENV == 'production'
        ? FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS_PROD
        : FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS;

    remoteConfig.defaultConfig = {
      manual_kyc_terms_and_conditions: JSON.stringify(
        TERMS_AND_CONDITIONS_DEFAULT_VALUE,
      ),
      dukcapil_passed_docs_needed: 3,
      all_banner_image_resolutions: JSON.stringify(
        BANNER_RESOLUTION_FIREBASE_DEFAULT_VALUE,
      ),
      portal_config: JSON.stringify([]),
      legal_docs: JSON.stringify(LEGAL_DOCS_DEFAULT_VALUE),
    };

    try {
      await fetchAndActivate(remoteConfig);
      setConfig(remoteConfig);
    } catch (error) {
      const message = 'Firebase remote config fetch and activate failed';
      Sentry.captureException(error);
      return Promise.reject(message);
    }
  }, []);

  const getFirebaseRemoteConfig = useCallback(
    async <T extends FirebaseOutputDataType>(
      key: FirebaseInputDataType,
    ): Promise<T | undefined> => {
      if (!config) return;
      try {
        const value = getValue(config as RemoteConfig, key);
        if (value) {
          return JSON.parse(value.asString());
        }

        return null;
      } catch (error) {
        const message = 'Firebase remote config getFirebaseRemoteConfig failed';
        Sentry.captureException(error);
        return Promise.reject(message);
      }
    },
    [config],
  );

  return {
    init,
    getFirebaseRemoteConfig,
  };
};

export default useFirebaseRemoteConfig;
