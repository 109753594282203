import { createRequestConfig } from '@src/service/createRequestConfig';

export const createBannerUsingPost = createRequestConfig<
  {
    requestBody: CreateOrEditBannerRequest;
  },
  Banner
>('createBannerUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/banners`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const deleteBannerUsingDelete = createRequestConfig<{
  bannerId: number;
}>('deleteBannerUsingDelete', ({ bannerId }) => ({
  url: `/admin-portal/v1/banners/${bannerId}`,
  method: 'DELETE',
}));

export const editBannerUsingPut = createRequestConfig<
  {
    bannerId: number;
    requestBody: CreateOrEditBannerRequest;
  },
  Banner
>('editBannerUsingPut', ({ bannerId, requestBody }) => ({
  url: `/admin-portal/v1/banners/${bannerId}`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const getBannerUsingGet = createRequestConfig<
  {
    bannerId: number;
  },
  Banner
>('getBannerUsingGet', ({ bannerId }) => ({
  url: `/admin-portal/v1/banners/${bannerId}`,
  method: 'GET',
}));

export const getBannersUsingGet = createRequestConfig<
  {
    appVersion?: string;
    category: keyof typeof GetBannersUsingGetCategory;
    isHideNotVisibleBanners?: boolean;
    name?: string;
    page?: number;
    size?: number;
  },
  PaginationResponseBanner
>(
  'getBannersUsingGet',
  ({ appVersion, page, size, name, category, isHideNotVisibleBanners }) => ({
    url: `/admin-portal/v1/banners/list`,
    method: 'GET',
    params: {
      appVersion,
      page,
      size,
      name,
      category,
      isHideNotVisibleBanners,
    },
  }),
);

export const reorderBannersUsingPost = createRequestConfig<
  {
    requestBody: ReOrderBannerRequest;
  },
  boolean
>('reorderBannersUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/banners/reorder`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface Banner {
  category?: keyof typeof BannerCategory;
  createdAt?: string;
  createdBy?: string;
  deletedAt?: string;
  id?: number;
  imageUrl?: { [key: string]: string };
  minAppVersion?: string;
  name?: string;
  position?: number;
  redirectUrl?: { [key: string]: string };
  updatedAt?: string;
  updatedBy?: string;
  visible?: boolean;
}

export enum BannerCategory {
  'PROMOTION' = 'PROMOTION',
  'EDUCATION' = 'EDUCATION',
  'UPDATES' = 'UPDATES',
}

export interface CreateOrEditBannerRequest {
  category: keyof typeof CreateOrEditBannerRequestCategory;
  imageUrl: { [key: string]: string };
  isVisible?: boolean;
  minAppVersion?: string;
  name: string;
  position?: number;
  redirectUrl: { [key: string]: string };
}

export enum CreateOrEditBannerRequestCategory {
  'PROMOTION' = 'PROMOTION',
  'EDUCATION' = 'EDUCATION',
  'UPDATES' = 'UPDATES',
}

export enum GetBannersUsingGetCategory {
  'PROMOTION' = 'PROMOTION',
  'EDUCATION' = 'EDUCATION',
  'UPDATES' = 'UPDATES',
}

export interface PaginationResponseBanner {
  items?: Banner[];
  totalElements?: number;
  totalPages?: number;
}

export interface ReOrderBannerRequest {
  afterBannerId?: number;
  bannerId: number;
}
